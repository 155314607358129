<template>
  <div class="main" ref="main">
    <!-- <div class="wrap_dialog" v-if="dialogWrap" @click="showDialog()">
      <div class="d4"></div> -->
      <!-- <ul
        class="dialogWrap"
        :style="vipComplaint === 'Y' ? 'height:5rem' : 'height:2.5rem'"
      >
        <li @click="showExit()">
          <img :src="exit" />
          <span>退出登录</span>
        </li>
      </ul> -->
    <!-- </div> -->
    <div class="wrap_gary_info">
      <van-row class="wrap_row" style="height:80px;line-height:80px" type="flex" justify="center">
        <van-col class="wrap_col" span="10">账户余额(元)</van-col>
        <van-col span="14">
          <img class="help_center" :src="tuichu" @click="showExit()" />
        </van-col>
      </van-row>
      <van-row class="wrap_row" style="height:130px;line-height:130px" type="flex" justify="center">
        <van-col class="wrap_col" span="10">
          <span :class="user.userInfor.balance < 0 ? 'red_font top_span' : 'top_span'">
            {{ user.userInfor.balance }}
          </span>
        </van-col>
        <van-col span="14">
            <span class="wrap_col_btn" @click="goPage('/pay', '')" v-if="paramInfo.wechatPayFlag === 'Y'">
              <span>充值>></span>
            </span>
        </van-col>
      </van-row>
    </div>
    <div class="list_money_wrap">
      <ul class="meter_list" :style="`background:#fff;`">
        <li class="center">
          <span class="list_span">预计可用(天)</span>
          <span class="list_span">日均消耗(元)</span>
        </li>
        <li class="footer">
          <span class="list_span">
            <span @click="goPage('/useDay', '')">{{ user.userInfor.remainingDays || "--" }}</span>
          </span>
          <span class="list_span">
            <span @click="goPage('/expenses', '')">{{ user.userInfor.avgFee || "--" }}</span>
          </span>
        </li>
      </ul>
    </div>

    <!-- <div class="recover" v-if="allow && elenum > 0">
      <span class="textone" @click="geAapplyFor()">
        本月还有 {{ elenum }} 次紧急复电>>
      </span>
    </div> -->
    <div class="list_wrap" v-if="metersListArr.length">
      <ul
        class="meter_list"
        v-for="(item, index) in metersListArr"
        :key="index"
        :style="`background:#fff;`"
        @click="goPage('/energyConsumption', item)"
      >
        <li class="top">
          <img class="typeImg" :src="item.type | filterIcon" />
          <span>
            <p>
              {{ !item.name ? '该电表暂无名称' : item.name}}
            </p>
            <p>
              <font class="gray_font">{{ item.assetNo }} ({{ item.kind | meterKind }})</font>
            </p>
          </span>
          <img class="flagImg" :src="item.controlState | filterIconType" />
        </li>
        <li class="center">
          <span class="list_span">
            {{ item.nowReadings || 0 }}
          </span>
          <span class="list_span">
            {{ item.amount || 0 }}
          </span>
        </li>
        <!-- <li class="footer">{{item.state | filterState}}</li> -->
        <li class="footer">
          <span class="list_span">当前示数</span>
          <span class="list_span">本月累计扣费(元)</span>
        </li>
      </ul>
    </div>
    <div v-else style="text-align: center" class="tips_footer">
      <van-empty description="----该账户暂无关联表计----" />
    </div>
    <div class="space">

    </div>
    <div class="footer">

    </div>
  </div>
</template>

<script>
import address from "@/assets/img/address.png";
import renovate from "@/assets/img/renovate.png";
import userList from "@/assets/img/user-list.png";
import { userDB } from "@/db";

import helpCenter from "@/assets/home/help_center.png";
import tuichu from "@/assets/home/tuichu.png";
// 电表
import dianbiao from "@/assets/home/dianbiao.png";
import noOkbig_3x from "@/assets/home/no-okbig_3x.png";
import okbig_3x from "@/assets/home/okbig_3x.png";
import state_On from "@/assets/home/state_On.png";
import state_Off from "@/assets/home/state_Off.png";

import detail_3x from "@/assets/home/detail_3x.png";
import bgl_3x from "@/assets/home/bgl_3x.png";
import electric from "@/assets/home/electric.png";
import noMessage from "@/assets/home/no_message.png";
import listBg from "@/assets/home/list_bg.png";
import feedback from "@/assets/home/feedback.png";
import exit from "@/assets/home/exit.png";
import roundBg from "@/assets/home/round_bg.png";

import { Dialog } from "vant";
import { mapState, mapActions, mapGetters } from "vuex";
import { bannerFetch, metersList, dayRead, userDetail } from "@/api/home";
import { getWxRecharge } from "@/api/wxSdk";
import api from "@/api/index";
import request from "@/utils/request";
const { fetchData } = api;

export default {
  data() {
    return {
      vipId: "",
      elenum: 0,
      timenum: 0,
      allow: false,
      onoff: false,
      bannerQuery: {
        type: "VIP_HOME",
      },
      vipComplaint: "N",
      unit: {
        GAS: "m³",
        POWER: "kwh",
        WATER: "m³",
      },
      dialogWrap: false,
      active: 0,
      roundBg,
      bgl_3x,
      feedback,
      exit,
      listBg,
      noMessage,
      helpCenter,
      tuichu,
      userList,
      renovate,
      address,
      dianbiao,
      noOkbig_3x,
      okbig_3x,
      state_On,
      state_Off,
      detail_3x,
      metersListArr: [],
      images: [],
      electric,
      fontlength: 0,
      paramInfo: {},
      pointStart: 0,
      pointEnd: 0,
      marquee_list: [
        {
          text:
            "为确保已入住用户在节日期间充分享受休息，放松心情，节假日期间，全天禁止噪音装修施工，请大家自觉遵守;",
          width: 0,
        },
      ],
    };
  },
  filters: {
    meterKind(status) {
      const statusMap = {
        VIP: "户用表",
        SHARE: "共用表",
        PUBLIC: "公摊表",
        TOTAL: "总表",
      };
      return statusMap[status];
    },
    filterIcon(status) {
      const statusMap = {
        POWER: dianbiao
      };
      return statusMap[status];
    },
    filterType(status) {
      const statusMap = {
        GAS: "气表",
        POWER: "电表",
        WATER: "水表",
      };
      return statusMap[status];
    },
    filterIconType(status) {
      const statusMap = {
        ON: state_On,
        OFF: state_Off,
        WAIT_INSTALL: noOkbig_3x,
        RUNNING: okbig_3x,
        SHUT_DOWN: noOkbig_3x,
      };
      return statusMap[status];
    },
    filterState(status) {
      const statusMap = {
        WAIT_INSTALL: "待安装",
        RUNNING: "运行中",
        SHUT_DOWN: "已关闭",
      };
      return statusMap[status];
    },
    filterClass(status) {
      const statusMap = {
        WAIT_INSTALL: "wait",
        RUNNING: "run",
        SHUT_DOWN: "shut",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["user", "global"]),
    font() {
      return process.env.VUE_APP_FONT_TITLE;
    },
    // marquee_list_width() {
    //   let width = 0
    //   for (let i = 0; i < this.marquee_list.length; i++) {
    //     this.fontlength += this.marquee_list[i].text.length
    //     width += 1 + (this.marquee_list[i].text.split('').length * 1)
    //     this.marquee_list[i].width = this.marquee_list[i].text.split('').length * 0.9
    //   }
    //   return width
    // }
    domar() {
      return this.marquee_list_width();
    },
  },
  methods: {
    marquee_list_width() {
      let width = 0;
      for (let i = 0; i < this.marquee_list.length; i++) {
        this.fontlength += this.marquee_list[i].text.length;
        width += 1 + this.marquee_list[i].text.split("").length * 1;
        this.marquee_list[i].width =
          this.marquee_list[i].text.split("").length * 0.9;
      }
      return width;
    },
    /*
      页面跳转
      @parmas name(跳转地址) obj(跳转所需要的参数)
    */
    async goPage(name, obj) {
      const query = obj;
      this.$router.push({
        path: `${name}`,
        query,
        // path: `/pay`
      });
    },
    geAapplyFor() {
      if (this.onoff) {
        Dialog.confirm({
          title: "提示",
          message: "确认申请紧急复电？",
          cancelButtonColor: "#666666",
        })
          .then(() => {
            // on confirm

            fetchData(
              { vipId: this.vipId },
              `/running/electricRecovery/whetherAllowRecovery`,
              "get"
            )
              .then((res) => {
                // console.log(res,'是否能行')
                // if (res.success) {

                request({
                  method: "post",
                  url: `/running/electricRecovery/electricRecoveryRecord`,
                  data: { vipId: this.vipId },
                })
                  .then((res) => {
                    Dialog.alert({
                      title: "申请成功",
                      message:
                        `
                  <div style="display: flex;
                  flex-flow: column;
                  justify-content: center;
                  align-items: center;">
                  <div style="width:196px;margin-bottom:30px;text-align:left;font-size:1rem;">电表将尽快合闸，请耐心等待！</div>
                  <div style="color:red;width:196px;text-align:left;font-size:1rem;">注意：若` +
                        this.timenum +
                        `小时后还是欠费，系统将再次断电，请尽快充值！</div>
                  </div>
                  `,
                      confirmButtonText: "关闭",
                    }).then(() => {
                      fetchData(
                        { vipId: this.vipId },
                        `/running/electricRecovery/configOfVip`,
                        "get"
                      )
                        .then((res) => {
                          this.elenum = res.timeOfMonthRemain;
                          this.timenum = res.duration;
                          this.allow = res.allow;
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    Dialog.alert({
                      title: "申请失败",
                      message:
                        `
                  <div style="display: flex;
                  flex-flow: column;
                  justify-content: center;
                  align-items: center;">
                  <div style="color:red;width:196px;text-align:left;font-size:1rem;">` +
                        err +
                        `!请联系电管家管理员！</div>
                  </div>
                  `,
                      confirmButtonText: "关闭",
                    }).then(() => {
                      // on close
                    });
                  });

                // } else {

                // }
              })
              .catch((err) => {
                console.log(err);
                Dialog.alert({
                  title: "申请失败",
                  message: `
                  <div style="display: flex;
                  flex-flow: column;
                  justify-content: center;
                  align-items: center;">
                  <div style="width:196px;margin-bottom:30px;text-align:left;font-size:1rem;">您已有一个申请正在执行中，请耐心等待！</div>
                  <div style="color:red;width:196px;text-align:left;font-size:1rem;">注意：若15分钟后还未复电，请联系电管家管理员！</div>
                  </div>
                  `,
                  confirmButtonText: "关闭",
                }).then(() => {
                  // on close
                });
              });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Dialog.alert({
          title: "失败",
          message: `
                  <div style="display: flex;
                  flex-flow: column;
                  justify-content: center;
                  align-items: center;">
                  <div style="width:196px;margin-bottom:30px;text-align:left;font-size:1rem;">您当前没有需要复电的电表！</div>
                  </div>
                  `,
          confirmButtonText: "关闭",
        }).then(() => {
          // on close
        });
      }
    },
    getVipComplaint() {
      fetchData({}, `/archive/vipComplaint/can`, "get")
        .then((res) => {
          this.vipComplaint = res.flag;
        })
        .catch((err) => {
          console.log(err, "getVipComplaint");
        });
    },
    async initData() {
      const _self = this;
      await this.getMeters();
      await this.getUser();
      await this.getPramaInfo();

      const token = userDB.get("token");
      this.$store.commit("SET_DATE");

      await fetchData({}, `/running/fees/vip/data`, "get")
        .then((res) => {
          const obj = {};
          const arrFeeAmount = [];
          const arrFeeDate = [];
          const arrRechargeAmount = [];
          const arrRechargeDate = [];
          for (let i = 0; i < res.fee.length; i++) {
            arrFeeAmount.push(res.fee[i].amount);
            arrFeeDate.push(res.fee[i].dataDate);
          }
          for (let i = 0; i < res.recharge.length; i++) {
            arrRechargeAmount.push(res.recharge[i].amount);
            arrRechargeDate.push(res.recharge[i].dataDate);
          }
          obj.fee = { amount: arrFeeAmount, dateDate: arrFeeDate };
          obj.recharge = {
            amount: arrRechargeAmount,
            dateDate: arrRechargeDate,
          };
          this.$store.commit("SET_RECHARGEFEELIST", obj);
        })
        .catch((err) => {
          console.log(err);
        });
      // await fetchData(
      //   { vipId: this.vipId },
      //   `/running/electricRecovery/configOfVip`,
      //   "get"
      // )
      //   .then((res) => {
      //     this.elenum = res.timeOfMonthRemain;
      //     this.timenum = res.duration;
      //     this.allow = res.allow;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getPramaInfo() {
      fetchData({}, `/config/paramInfo`, "get")
        .then((res) => {
          this.paramInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 刷新
    refresh() {
      const appidName = userDB.get("appId");
      localStorage.clear();
      userDB.set("appId", appidName);
      this.$store.dispatch("getWxPath").then((url) => {
        // TODO 跳转微信页面,暂时注释,下面两行注释掉还原newx()可以直接接入本地开发环境，反之拦截进入微信
        location.href = decodeURIComponent(url);
      });
    },
    showExit() {
      const _self = this;
      this.dialogWrap = false;
      Dialog.confirm({
        title: "退出登录",
        message: "是否退出当前账户登录",
      })
        .then(() => {
          // on confirm
          this.dialogWrap = false;
          fetchData({}, `/archive/vip/untie`, "put")
            .then((res) => {
              return res;
            })
            .catch((err) => {
              Dialog.alert({
                title: "退出失败",
                message: err,
              });
              console.log(err);
              return err;
            });
        })
        .then((res) => {
          _self.refresh();
        })
        .catch(() => {
          // on cancel
          this.dialogWrap = false;
        });
    },
    showDialog() {
      this.dialogWrap = !this.dialogWrap;
    },
    /*
      获取用户信息
    */
    async getUser() {
      const _self = this;
      try {
        const res = await userDetail();
        this.$store.commit("SET_USERINFOR", res);
        this.vipId = userDB.get("vipId");
      } catch (error) {
        // 数据错误刷新
        // this.refresh()
        return Promise.reject(error);
      }
    },
    async getMeters() {
      const res = await metersList();
      this.$store.commit("SET_METERLIST", res.list);
      this.metersListArr = res.list;

      for (let i = 0; i < this.metersListArr.length; i++) {
        if (this.metersListArr[i].controlState == "OFF") {
          this.onoff = true;
        }
      }
    },
    async refurbishData(item, id) {
      // 尝试修改该项的值成功
      const res = await dayRead(id);
      item.nowReadings = res.data.result.readings;
    },
    imgLink(url) {},
    /*
      加法函数
      @parmas arg1(被加数字) arg2(被加数字)
      @return 返回计算结果
    */
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    reaload() {
      this.$router.push({ path: "/index" });
      this.$router.go(-1);
    },
  },
  created() {
    this.initData();
    // this.getVipComplaint()
  },
  mounted() {
    const self = this;
    this.$refs.main.addEventListener("touchstart", function (e) {
      // 如果loading为true就代表刷新函数正在进行，此时阻止下拉操作，返回
      self.pointStart = e.targetTouches[0].clientY;
    });

    this.$refs.main.addEventListener("touchend", function (e) {
      // 如果loading为true就代表刷新函数正在进行，此时阻止下拉操作，返回
      self.pointEnd = e.changedTouches[0].clientY;
      const scrollTop = self.$refs.main.scrollTop || 0;
      // console.log(scrollTop, self.pointEnd, self.pointStart)
      if (scrollTop === 0 && self.pointEnd - self.pointStart > 70) {
        self.initData();
      }
      self.pointStart = 0;
      self.pointEnd = 0;
    });
  },
};
</script>
<style src="./home.css"></style>
<style lang="scss" scoped>
@keyframes marquee {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}
.gray_font {
  color: #A3A3A3;
}
.wrap_dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.d4 {
  position: absolute;
  z-index: 20;
  top: 2.5rem;
  right: 1.2rem;
  float: left;
  width: 0;
  height: 0;
  border-width: 0.6rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  transform: rotate(180deg);
}
.recover {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1.8rem;
  .textone {
    cursor: pointer;
    margin-top: 3rem;
    margin-bottom: 1.8rem;
    font-size: 1rem;
    font-family: Microsoft YaHei;
    color: #d85252;
  }
}

.dialogWrap {
  position: absolute;
  z-index: 20;
  width: 7.5rem;
  height: 8rem;
  top: 3.6rem;
  right: 0.8rem;
  background: #fff;
  padding: 0 0.5rem;
  li {
    float: left;
    height: 2.5rem;
    line-height: 2.5rem;
    color: #666;
    img {
      height: 1.6rem;
      float: left;
      margin: 0.5rem 0.5rem;
    }
  }
  .top_li {
    background: none;
    pointer-events: none;
    height: 1rem;
    line-height: 1rem;
    position: relative;
    i {
      overflow: hidden;
      float: left;
      display: block;
      width: 0;
      height: 0;
      border-width: 0 20px 20px;
      border-style: solid;
      border-color: transparent transparent #fff; /*透明 透明  黄*/
      position: absolute;
      bottom: 0;
      right: 0.5rem;
    }
  }
}
.help_center {
  position: absolute;
  z-index: 10;
  top: 1.2rem;
  right: 0.8rem;
  width: 2rem;
}
.main {
  background: #f0f0f0;
  height: 100%;
  overflow: auto;
}
.refresh {
  width: 1rem;
  margin-right: 1rem;
}
.wrap_gary {
  height: auto;
  overflow: hidden;
  float: left;
  width: 100%;
}
.van-swipe {
  height: 10rem;
  overflow: hidden;
}
.listNum {
  margin-left: 1rem;
  font-size: 1.2rem;
}
.tips_footer {
  width: 100%;
  margin-top: 1rem;
  font-size: 0.9rem;
  float: left;
  text-align: center;
  color: #666;
  img {
    width: 40%;
  }
}
.wrap_box {
  width: 100%;
  height: 3rem;
  float: left;
  overflow: hidden;
  .left {
    float: left;
    width: 3rem;
    background: #e71f19;
    color: #fff;
    border-radius: 15px;
    text-align: center;
    height: 1.6rem;
    line-height: 1.6rem;
    margin: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  .right {
    float: left;
    height: 2.6rem;
    line-height: 2.6rem;
    width: calc(100% - 5rem);
    position: relative;
  }
}
.marquee_wrap {
  position: relative;
  width: 100%;
  line-height: 2.6rem;
  overflow: hidden;
  height: 2.6rem;
  flex-wrap: nowrap;
  color: #666;
  font-size: 0.8rem;
  div {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0%;
    width: auto;
    animation: marquee 10s linear infinite;
    display: flex;
    flex-direction: row;
    span {
      display: inline-block;
      float: left;
      padding-right: 1rem;
      text-align: center;
      width: auto;
      overflow: hidden;
    }
  }
}
.list_wrap {
  width: 100%;
  height: auto;
  float: left;
  color: #333;
  line-height: 4rem;
  font-size: 0.7rem;
  p {
    width: 96%;
    margin: 0;
    color: #0B0B0B;
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 0.9rem;
  }
  .typeImg {
    margin-top: 1.1rem;
    width: 40px;
    float: left;
    height: 40px;
    line-height: 40px;
  }
  .meter_list {
    background: #e5f0ff;
    width: 90%;
    margin: 0 5%;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    text-align: left;
    height: 9rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    float: left;
    li {
      width: 90%;
      height: 1.8rem;
      line-height: 1.8rem;
      color: #A3A3A3;
      padding: 0 5%;
    }
    .top {
      height: 4.4rem;
      line-height: 4.4rem;
      padding: 0;
      margin: 0 5%;
      border-bottom: 1px solid #e5e5e5;
      span {
        display: inline-block;
        margin-left: 0.3rem;
        font-size: 0.9rem;
        height: 2.7rem;
        line-height: 2.7rem;
        max-width: 84%;
        font {
          font-size: 0.7rem;
        }
      }
      .flagImg {
        width: 1.2rem;
        float: right;
        margin-top: 0.8rem;
      }
    }
    .list_span {
      display: inline-block;
      width: 50%;
      float: left;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 300;
      color: #A3A3A3;
    }
    .center {
      margin-top: 1rem;
      height: 1rem;
      line-height: 1rem;
      .list_span {
        font-size: 1rem;
        font-weight: 500;
        color: #0B0B0B;
      }
    }
  }
  .list {
    .renovate {
      width: 70px;
      height: auto;
      margin: 0 10px;
    }
    width: 47%;
    float: left;
    margin: 0.4rem 3%;
    margin-right: 0;
    margin-bottom: 0;
    .right {
      width: 78%;
      text-align: left;
      li:first-child {
        font-size: 1.1rem;
        font-weight: 500;
        color: #333;
      }
    }
  }
}
.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .left {
    flex-grow: 1;
    text-align: center;
    line-height: 3rem;
  }
  .right {
    flex-grow: 1;
    text-align: center;
    flex-direction: column;
    line-height: 1.5rem;
    font-size: 0.8rem;
    li:first-child {
      font-size: 1.1rem;
      color: #e71f19;
    }
  }
}

.list_money_wrap {
  width: 100%;
  height: auto;
  float: left;
  color: #333;
  line-height: 2rem;
  font-size: 0.7rem;

  .meter_list {
    background: #e5f0ff;
    width: 90%;
    margin: 0 5%;
    margin-top: -2.5rem;
    margin-bottom: 0.6rem;
    text-align: left;
    height: 6rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    float: left;

    li {
      width: 90%;
      height: 3.3rem;
      line-height: 3.3rem;
      padding: 0 5%;
    }


    .list_span {
      display: inline-block;
      width: 50%;
      float: left;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 550;
      color: #00A49C;
    }

    .center {
      height: 2.7rem;
      line-height: 2.7rem;

      .list_span {
        font-size: 1.0rem;
        font-weight: 350;
        color: #00A49C;
      }
    }
  }
}
.wrap_gary_info {
  background: #00A39B;
  height: 300px;
  overflow: hidden;
  float: left;
  width: 100%;
}
.wrap_row{
  background: #00A39B;

  .wrap_col {
      padding: 0 20px;
      color: #FFF;

      .top_span {
        font-size: 2rem;
      }

      .red_font {
        color: #e71f19;
      }
    }

    .wrap_col_btn{
      display: inline-block;
        width: 80px;
        height: 40px;
        font-size: 1rem;
        line-height: 40px;
        background: none;
        border: 1px solid #fff;
        border-radius: 5px;
        background: #ffbe3e;
        color: #fff;
        text-align: center;
    }
}
.money {
  width: 100%;
  height: 360px;
  margin: 2rem 0;
  position: relative;
  margin-top: 0;
  float: left;
  .infor_ul {
    width: 100%;
    text-align: center;
    float: left;
    background: #00A39B;
    .red_font {
      color: #e71f19;
    }
    li {
      min-height: 1.4rem;
      height: auto;
      line-height: 1.4rem;
      font-size: 1rem;
      color: #fff;
      .main_ul {
        width: 100%;
        text-align: center;
        height: 130px;
        float: left;
        margin-bottom: 1.4rem;
        li {
          margin-left: 6.5%;
          width: 40%;
          border-radius: 15px;
          float: left;
          height: 4rem;
          text-align: center;
          div {
            width: 100%;
            float: left;
            span {
              display: inline-block;
              width: 100%;
              color: #fff;
              text-align: left;
              overflow: hidden;
              text-align: center;
            }
            span:nth-child(1) {
              height: 1.7rem;
              line-height: 1.7rem;
              font-size: 1.3rem;
              float: left;
            }
            span:nth-child(2) {
              height: 1rem;
              line-height: 1rem;
              font-size: 0.7rem;
              float: left;
            }
          }
        }
        .center {
          width: 1px;
          margin-left: 3.6%;
          div {
            margin-top: 20px;
            height: 60px;
            background: #fff;
            opacity: 0.2;
          }
        }
      }
      .top_round {
        width: 170px;
        height: 170px;
        margin: 0 auto;
        padding: 20px;
        margin-top: 40px;
        .top_span {
          display: inline-block;
          float: left;
          width: 150px;
          margin: 5px 10px;
          margin-top: 65px;
          font-size: 20px;
        }
        .bottom_span {
          font-size: 0.9rem;
          display: inline-block;
          float: left;
          width: 150px;
          margin: 5px 10px;
        }
      }
    }
  }
}

.button {
  display: inline-block;
  width: 120px;
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
  background: none;
  border: 1px solid #fff;
  border-radius: 100px;
  background: #ffbe3e;
  color: #fff;
  text-align: center;
  position: absolute;
  z-index: 1;
  box-shadow: 6px 6px 6px #dadada;
  margin-left: -62px;
  left: 50%;
  top: 340px;
}
.rightBlock {
  width: 100px;
}
.bannerImg {
  width: 100%;
}
.payBtn {
  margin-left: 20px;
  background-color: #4fb4e9;
  color: #fff;
}
.detailBtn {
  background-color: #999;
  color: #fff;
}
.wait {
  color: #ff6666;
}
.run {
  color: #4fb4e9;
}
.shut {
  color: #ffbe3e;
}
.addr {
  position: absolute;
  bottom: -5px;
  left: 90px;
  font-size: 12px;
  color: #999;
}
.userBlock .title {
  font-size: 28px;
}
.userBlock .name {
  font-size: 24px;
}
.userBlock div {
  text-align: center;
}
.space {
  height: 100px;
}
.locationIcon {
  width: 20px;
  height: 24px;
  position: relative;
  top: 4px;
  margin-right: 10px;
}
.renovate {
  width: 36px;
  height: 30px;
  margin-left: 10px;
  position: relative;
  top: 4px;
}
.userList {
  width: 45px;
  height: 35px;
  margin-left: 10px;
  position: relative;
  top: 8px;
}
</style>
<style>
.iconfont div.van-tabbar-item__text {
  font-size: 14px;
}
div {
  border-bottom: none;
}
</style>
